<template>
	<!-- <div class="goods-list">
    <template v-if="goodsList.length > 0">
      <router-link tag="div" class="goods-grid" :to="'/ShopGoodsDetails?types=1&id=' + item.goods_id" v-for="(item, index) in goodsList" :key="index">
        <img :src="item.cover_imgurl" />
        <div class="goods-info">
          <div class="goods-name">{{ item.goods_name }}</div>
          <div class="return" v-if="userInfo.user_types == 2">饭佣 ￥{{ userInfo.referrer_lv == 1?item.commission1:item.commission2 }}</div>
          <div class="price-row">
            <div class="price-now">￥<span>{{ item.sale_price }}</span></div>
            <div class="price-old">￥{{ item.market_price }}</div>
          </div>
        </div>
      </router-link>
      <load-more :loadLastText='loadLastText' @getMore="getMore"></load-more>
    </template>
    <no-data v-else></no-data>
  </div> -->
	<goods-list :recommends="goodsList" :finished="loadLastText" :isShow="isShow" :goodsTab="3" @getMore="getMore" ref="goodsList"></goods-list>
</template>

<script>
	import {
		getGoodsSearchKey
	} from "@/http/api";
	import GoodsList from "@/components/goods/GoodsList";

	export default {
		components: {
			GoodsList
		},
		data() {
			return {
				site_code: '',
				searchVal: '',
				pages: 1,
				loadLastText: false,
				goodsList: [],
				isShow: false,
				userInfo: {}
			};
		},
		mounted() {
			if (this.$route.query.key != '') {
				this.searchVal = this.$route.query.key
				this.site_code = this.$LStorage.getItem('curSiteInfo').site_code

				this.getGoodsList();
			} else {
				this.$toast.success("检索商品关键词为空")
				this.$router.replace({
					path: "/ShopMall/search/info"
				})
			}
		},
		beforeRouteEnter(to, from, next) {
			// 在渲染该组件的对应路由被 confirm 前调用
			// 不！能！获取组件实例 `this`
			// 因为当守卫执行前，组件实例还没被创建
			if (from.name == 'ShopGoodsDetails') {
				to.meta.keepAlive = true;
			} else {
				to.meta.keepAlive = false;
			}
			// console.log(to)
			next();
		},
		methods: {
			// 点击搜索
			async getGoodsList() {
				const res = await getGoodsSearchKey({
					data: {
						pages: this.pages,
						goods_name: this.searchVal,
						site_code: this.site_code,
					}
				});
				this.isShow = true;
				this.$refs.goodsList.changeLoading(false);
				if (res.numPage == res.data.length) {
					this.loadLastText = false;
				} else {
					this.loadLastText = true;
				}
				this.goodsList = this.goodsList.concat(res.data);
			},
			getMore() {
				if (!this.loadLastText) {
					this.pages++;
					this.getGoodsList();
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.goods-list {
		padding: 3vw;
		min-height: 100vh;
		background-color: #f6f6f6;

		.goods-grid {
			width: 100%;
			height: 28vw;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 5px;
			border-radius: 5px;
			background: #fff;
			overflow: hidden;
			margin-bottom: 0.2rem;
			box-sizing: border-box;

			img {
				width: 24vw;
				height: 24vw;
				object-fit: cover;
			}

			.goods-info {
				width: calc(100% - 27vw);
				height: 24vw;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			.goods-name {
				font-size: .28rem;
				line-height: 1.4;
				color: #323233;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}

			.return {
				height: .4rem;
				width: 7em;
				font-size: .26rem;
				background: #ff6300;
				color: #fff;
				padding: 0 .1rem;
				border-radius: .4rem;
				display: inline;
				text-align: center;
			}

			.price-row {
				font-size: .24rem;
				display: flex;
				align-items: flex-end;
				line-height: 1;

				.price-now {
					color: #e50012;

					span {
						font-size: .36rem;
						font-weight: bold;
					}
				}

				.price-old {
					text-decoration: line-through;
					color: #999999;
					margin-left: 10px;
				}
			}
		}
	}
</style>
